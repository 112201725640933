import React, { Fragment } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import Bar from "components/Bar"
import UserCard from "./UserCard"
import OptionsSection from "./OptionsSection"
import Section from "./Section"
import { save } from "flows/users/actions"
import SaveButton from "./SaveButton"

import { addRole, removeRole } from "flows/users/actions"

const OptionsSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledSaveButton = styled(SaveButton)`
  color: white;
  border-color: white;
  float: right;
`

/**
 * Map over the current user and services to map the users current roles
 * against the available services.
 */
const currentRolesFor = (user, rolesPerService) => {
    const currentRoles = {}

    rolesPerService.forEach(service => {
        currentRoles[service.id] = user.service_roles[service.claim_name] || []
    })

    return currentRoles
}

/**
 * Returns an array where choosen options are set as selected
 */
const optionsByService = (rolesPerService, usersCurrentRoles) => {
    return rolesPerService.reduce((options_by_service, service) => {
        const primary_options = service.roles.filter(
            s => s.attributes && s.attributes.is_primary
        )
        const secondary_options = service.roles.filter(
            s => !s.attributes || !s.attributes.is_primary
        )

        return Object.assign({}, options_by_service, {
            [service.id]: {
                primary: primary_options.map(option => ({
                    label: option.attributes.name,
                    selected: usersCurrentRoles[service.id].includes(
                        option.attributes.name
                    ),
                })),
                secondary: secondary_options.map(option => ({
                    label: option.attributes.name,
                    selected: usersCurrentRoles[service.id].includes(
                        option.attributes.name
                    ),
                })),
            },
        })
    }, {})
}

const User = ({ capabilities, rolesPerService, user, addRole, removeRole, save, saving }) => {
    if (!user) {
        return null
    }

    const usersCurrentRoles = currentRolesFor(user, rolesPerService)

    const options_by_service = optionsByService(
        rolesPerService,
        usersCurrentRoles
    )

    const handleRoleChange = (option_type, service_index, option_index) => {
        const service = rolesPerService[service_index]
        const claimName = service.claim_name
        const roleName =
      options_by_service &&
      options_by_service[service.id] &&
      options_by_service[service.id][option_type] &&
      options_by_service[service.id][option_type][option_index] &&
      options_by_service[service.id][option_type][option_index].label
        if (!roleName) {
            throw new Error("Invalid role reference given to handleRoleChange().")
        }

        if (option_type === "secondary") {
            if (usersCurrentRoles[service.id].includes(roleName)) {
                removeRole(claimName, roleName)
            } else {
                addRole(claimName, roleName)
            }
        } else {
            options_by_service[service.id].primary.forEach(option => {
                if (option.selected) {
                    removeRole(claimName, option.label)
                }
            })
            if (!usersCurrentRoles[service.id].includes(roleName)) {
                addRole(claimName, roleName)
            }
        }
    }

    const canManageRoles = Boolean(capabilities.canManageRoles)

    return (
        <Fragment>
            <Bar>
                <a
                    href="/"
                    onClick={event => {
                        event.preventDefault()
                        window.history.back()
                    }}
                >
          &larr; Back to list
                </a>
            </Bar>
            <div data-testid="user">
                <UserCard user={user} />
                {canManageRoles && <Section oceanBlue>
                    <OptionsSectionWrapper data-testid="role-options">
                        {rolesPerService.map((service, index) => (
                            <OptionsSection
                                width={100 / rolesPerService.length}
                                key={index}
                                label={service.name}
                                section_id={index}
                                primary_options={options_by_service[service.id].primary}
                                secondary_options={options_by_service[service.id].secondary}
                                onChange={handleRoleChange}
                            />
                        ))}
                    </OptionsSectionWrapper>
                </Section>}
                {canManageRoles && <Section oceanBlue>
                    <StyledSaveButton save={save} saving={saving} id="save-user-button" data-testid="save-user-button" />
                </Section>}
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        capabilities: state.capabilities,
        rolesPerService: state.roles_per_service,
        user: state.users.list.find(user => user.selected),
        saving: state.users.saving,
    }
}

const mapDispatchToProps = dispatch => ({
    addRole: (claim_name, role) => dispatch(addRole(claim_name, role)),
    removeRole: (claim_name, role) => dispatch(removeRole(claim_name, role)),
    save: () => dispatch(save()),
})

export default connect(mapStateToProps, mapDispatchToProps)(User)
