import { createLogic } from "redux-logic"
import axios from "axios"

import { LOAD_CONFIG, LOADED_CONFIG } from "./action_types"

import { dispatchError } from "utilities/dispatch-error"

const loadConfig = createLogic({
    type: LOAD_CONFIG,

    async process(_, dispatch, done) {
        try {
            const response = await axios.get("/config/auth0.json", {
                validateStatus: status => status === 200,
            })
            dispatch({
                type: LOADED_CONFIG,
                config: response.data,
            })
        } catch (err) {
            dispatchError(dispatch, err)
        }

        done()
    },
})

export default [loadConfig]
