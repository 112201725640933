import produce from "immer"

import { ADD_FILTER, REMOVE_FILTER } from "./action_types"

const defaultState = {}

// State structure:
// {
//     [claim_name]: [<filter_name>]
// }

export default (state = defaultState, action) =>
    produce(state, draft => {
        switch (action.type) {
        case ADD_FILTER:
            draft[action.claim_name] = draft[action.claim_name] || []
            draft[action.claim_name] = draft[action.claim_name].filter(
                f => f !== action.filter_name
            )
            draft[action.claim_name].push(action.filter_name)
            break
        case REMOVE_FILTER: {
            draft[action.claim_name] = draft[action.claim_name] || []
            draft[action.claim_name] = draft[action.claim_name].filter(
                f => f !== action.filter_name
            )
            break
        }
        default:
            return state
        }
    })
