import React from "react"
import classNames from "classnames"

const Bar = props => {
    const barClassNames = classNames("layout-rt__bar", {
        "layout-rt__bar--bold": props.bold,
    })

    return (
        <div className={barClassNames} data-testid="bar">
            {props.children}
        </div>
    )
}

export default Bar
