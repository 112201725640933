import { createLogic } from "redux-logic"
import axios from "axios"

import { LOAD_CAPABILITIES, LOADED_CAPABILITIES } from "./action_types"

import { dispatchError } from "utilities/dispatch-error"

const loadCapabilities = createLogic({
    type: LOAD_CAPABILITIES,

    async process({ getState }, dispatch, done) {
        const { auth } = getState()
        try {
            const response = await axios({
                method: "get",
                url: "/api/capabilities",
                headers: { authorization: `Bearer ${auth.accessToken}` },
                validateStatus: status => status === 200,
            })

            dispatch({
                type: LOADED_CAPABILITIES,
                capabilities: response.data,
            })
        } catch (err) {
            dispatchError(dispatch, err)
        }
        done()
    },
})

export default [loadCapabilities]
