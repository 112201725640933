import produce from "immer"

import { LOADED_CONFIG } from "./action_types"

const defaultState = {}

export default (state = defaultState, action) =>
    produce(state, () => {
        switch (action.type) {
        case LOADED_CONFIG:
            return action.config
        default:
            return state
        }
    })
