import React from "react"
import classNames from "classnames"

const Dropdown = ({ children, open, right }) => {
    const classes = classNames("dropdown", {
        "dropdown--open": open,
        "dropdown--right": right,
    })

    return (
        <div className={classes}>
            <div className="dropdown__inner">{children}</div>
        </div>
    )
}

export default Dropdown
