import React from "react"
import { connect } from "react-redux"
import { Router, navigate } from "@reach/router"
import styled from "styled-components"

import "./stylesheets/App.scss"
import LogoBar from "./components/LogoBar"
import UserSearch from "./components/UserSearch"
import User from "./components/User"
import Layout from "./components/Layout"
import EditUserModal from "./components/EditUserModal"
import Toasts from "./components/Toasts"

import { login, handleAuthentication } from "flows/authentication/actions"

const StyledLayout = styled(Layout)`
  padding-bottom: 0;
`

const App = ({ auth, login, handleAuthentication, toasts, config }) => {
    const Login = () => {
        login(config)
        return <p>Redirecting to login...</p>
    }

    const Callback = ({ location, auth }) => {
        if (
            auth.accessToken === undefined &&
            auth.error === undefined &&
            /access_token|id_token|error/.test(location.hash)
        ) {
            handleAuthentication()
        }

        if (auth.error) {
            return (
                <p>An error occured when setting up the session: {auth.error.error} </p>
            )
        } else if (auth.accessToken) {
            navigate("/")
            return null
        } else {
            return <p>Setting up session...</p>
        }
    }

    if (Object.entries(config).length === 0) {
        return <p>Loading configuration...</p>
    } else {
        return (
            <Layout>
                <StyledLayout main>
                    <LogoBar />
                    <Layout body>
                        <Router>
                            {auth.accessToken ? <UserSearch path="/" /> : <Login path="/" />}
                            <User path="/user" />
                            <Callback auth={auth} path="/callback" />
                        </Router>
                    </Layout>
                </StyledLayout>
                <EditUserModal />
                <Toasts toasts={toasts} />
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        toasts: state.messages.list,
        config: state.config,
    }
}

const mapDispatchToProps = dispatch => ({
    login: config => dispatch(login(config)),
    handleAuthentication: () => dispatch(handleAuthentication()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
