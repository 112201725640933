import React from "react"

const Fact = ({ facts }) => (
    <div className="fact">
        {facts.map((fact, index) => (
            <div className="fact__item" key={index}>
                <h3 className="fact__item-title">{fact.title}</h3>
                <p className="fact__item-content">{fact.content}</p>
            </div>
        ))}
    </div>
)

export default Fact
