import {
    ADD_ROLE,
    REMOVE_ROLE,
    SELECT_USER,
    SEARCH_USERS,
    SAVE_USER,
    SET_USER_PROPERTY,
} from "./action_types"

export const addRole = (claim_name, role_name) => ({
    type: ADD_ROLE,
    claim_name: claim_name,
    role_name: role_name,
})

export const removeRole = (claim_name, role_name) => ({
    type: REMOVE_ROLE,
    claim_name: claim_name,
    role_name: role_name,
})

export const selectUser = user_id => ({
    type: SELECT_USER,
    user_id: user_id,
})

export const search = () => ({
    type: SEARCH_USERS,
})

export const save = () => ({
    type: SAVE_USER,
})

export const setProperty = (propertyPath, value) => ({
    type: SET_USER_PROPERTY,
    propertyPath: propertyPath,
    value: value,
})
