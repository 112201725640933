import React from "react"
import styled from "styled-components"

import "utilities/hyphenate"
import OptionsGroup from "./OptionsGroup"

const StyledOptionsSection = styled.div`
  @media (min-width: 968px) {
    width: calc(${props => props.width}% - 32px);
  }
`

const OptionsSection = ({
    id,
    section_id,
    label = "",
    primary_options,
    secondary_options,
    width,
    onChange,
}) => (
    <StyledOptionsSection
        className="options-section options-section--visible"
        data-label={label}
        id={id}
        data-testid={`options-section-${label.hyphenate()}`}
        width={width}
    >
        <div className="options-section__inner">
            <div
                tabIndex="0"
                className="options-section__label"
                data-testid="options-section-label"
                title={label}
            >
                {label}
            </div>
            {primary_options && primary_options.length > 0 && (
                <OptionsGroup
                    type="radio"
                    label="Primary"
                    onClick={option_index => {
                        onChange("primary", section_id, option_index)
                    }}
                    options={primary_options}
                    name={section_id}
                />
            )}
            {secondary_options && secondary_options.length > 0 && (
                <OptionsGroup
                    type="checkbox"
                    label="Secondary"
                    onClick={option_index => {
                        onChange("secondary", section_id, option_index)
                    }}
                    options={secondary_options}
                    name={section_id}
                />
            )}
        </div>
    </StyledOptionsSection>
)

export default OptionsSection
