import React from "react"
import Toast from "./Toast"

const Toasts = ({ toasts }) => (
    <div className="toasts" data-testid="toasts">
        {toasts && toasts.map((toast, i) => <Toast key={i} {...toast} />)}
    </div>
)

export default Toasts
