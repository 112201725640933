import produce from "immer"

import { ERROR_MESSAGE, DISMISS_MESSAGES } from "./action_types"

const defaultState = {
    list: [],
}

export default (state = defaultState, action) =>
    produce(state, draft => {
        switch (action.type) {
        case ERROR_MESSAGE:
            draft.list.push({
                type: "error",
                message: action.message,
            })
            break

        case DISMISS_MESSAGES:
            return defaultState

        default:
            return state
        }
    })
