import produce from "immer"

import { LOADED_CAPABILITIES } from "./action_types"

const defaultState = {}

export default (state = defaultState, action) =>
    produce(state, () => {
        switch (action.type) {
        case LOADED_CAPABILITIES:
            return action.capabilities
        default:
            return state
        }
    })
