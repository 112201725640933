import React from "react"

import "utilities/hyphenate"

const Input = ({ label = "", value, onChange }) => (
    <div className="form-item form-item--text" data-testid="input">
        <label className="form-item__label">{label}</label>
        <input
            data-testid={`input-field-${label.hyphenate()}`}
            type="text"
            name={label}
            className="form-item__element "
            placeholder={`Enter ${label}`}
            value={value}
            onChange={onChange}
        />
    </div>
)

export default Input
