import React from "react"
import { connect } from "react-redux"

import SearchField from "../SearchField"
import OptionsSection from "../OptionsSection"

import { addFilter, removeFilter } from "flows/active_filters/actions"
import { search } from "flows/users/actions"

const Search = () => {
    return (
        <div className="filterblock__action" data-testid="filterblock-search">
            <SearchField />
        </div>
    )
}

/**
 * Returns an array where choosen options are set as selected
 */
const optionsByService = (roles_per_service, active_filters) => {
    return roles_per_service.reduce((options_by_service, service) => {
        const primary_options = service.roles.filter(
            s => s.attributes && s.attributes.is_primary
        )
        const secondary_options = service.roles.filter(
            s => !s.attributes || !s.attributes.is_primary
        )

        return Object.assign({}, options_by_service, {
            [service.id]: {
                primary: primary_options.map(option => ({
                    label: option.attributes.name,
                    selected:
            active_filters[service.claim_name] &&
            active_filters[service.claim_name].includes(option.attributes.name),
                })),
                secondary: secondary_options.map(option => ({
                    label: option.attributes.name,
                    selected:
            active_filters[service.claim_name] &&
            active_filters[service.claim_name].includes(option.attributes.name),
                })),
            },
        })
    }, {})
}

const FilterBlock = ({
    capabilities,
    roles_per_service,
    active_filters,
    addFilter,
    removeFilter,
    search,
}) => {
    const options_by_service = optionsByService(
        roles_per_service,
        active_filters
    )

    const canUseFilter = Boolean(capabilities.canUseRoleFilter)

    const handleFilterChange = (option_type, service_index, option_index) => {
        const service = roles_per_service[service_index]
        const claim_name = service.claim_name
        const filter_name =
      options_by_service &&
      options_by_service[service.id] &&
      options_by_service[service.id][option_type] &&
      options_by_service[service.id][option_type][option_index] &&
      options_by_service[service.id][option_type][option_index].label
        if (!filter_name) {
            throw new Error("Invalid role reference given to handleFilterChange().")
        }

        if (option_type === "secondary") {
            if (
                active_filters[claim_name] &&
        active_filters[claim_name].includes(filter_name)
            ) {
                removeFilter(claim_name, filter_name)
            } else {
                addFilter(claim_name, filter_name)
            }
        } else {
            options_by_service[service.id].primary.forEach(option => {
                if (option.selected) {
                    removeFilter(claim_name, option.label)
                }
            })
            if (
                !active_filters[claim_name] ||
        !active_filters[claim_name].includes(filter_name)
            ) {
                addFilter(claim_name, filter_name)
            }
        }
        search()
    }

    return (
        <form
            action=""
            aria-controls=""
            className="filterblock"
            data-filter-indicator="filter-indicator"
            autoComplete="off"
            data-testid="filterblock"
        >
            <Search />
            {canUseFilter && <div
                className="filterblock__action filterblock__grid"
                data-testid="filterblock-filters"
            >
                {roles_per_service.map((service, index) => {
                    return (
                        <OptionsSection
                            width={100 / roles_per_service.length}
                            key={index}
                            label={service.name}
                            section_id={index}
                            primary_options={options_by_service[service.id].primary}
                            secondary_options={options_by_service[service.id].secondary}
                            onChange={handleFilterChange}
                        />
                    )
                })}
            </div>}
        </form>
    )
}

const mapStateToProps = state => ({
    capabilities: state.capabilities,
    roles_per_service: state.roles_per_service,
    active_filters: state.active_filters,
})

const mapDispatchToProps = dispatch => ({
    addFilter: (claim_name, filter_name) =>
        dispatch(addFilter(claim_name, filter_name)),
    removeFilter: (claim_name, filter_name) =>
        dispatch(removeFilter(claim_name, filter_name)),
    search: () => dispatch(search()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterBlock)
