const { ERROR_MESSAGE } = require("flows/messages/action_types")

function dispatchError(dispatch, err) {
    let message = err.message
    if (err.response) {
        message = `${message}: ${err.response.data}`
    }
    dispatch({
        type: ERROR_MESSAGE,
        message,
    })
}

module.exports = { dispatchError }