import produce from "immer"

import {
    LOADED_ROLES,
} from "./action_types"

const defaultState = []

export default (state = defaultState, action) =>
    produce(state, () => {
        switch (action.type) {
        case LOADED_ROLES:
            return action.roles_per_service
        default:
            return state
        }
    })
