import React from "react"

import "utilities/hyphenate"

const OptionsGroupItem = ({
    id,
    type,
    name,
    value,
    label = "",
    selected,
    onClick,
}) => (
    <div
        className="options-group__item"
        data-testid="options-group-item"
        onClick={onClick}
    >
        <input
            className="options-group__option"
            type={type}
            name={name}
            value={value}
            id={id}
            checked={selected}
            readOnly={true}
            data-testid={`options-group-item-input-for-${label.hyphenate()}`}
        />
        <label
            className="options-group__option-label"
            htmlFor={id}
            data-count=" "
            data-testid={`options-group-item-label-for-${label.hyphenate()}`}
        >
            {label}
        </label>
    </div>
)

export default OptionsGroupItem
