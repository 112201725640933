import React from "react"

const CloseButton = ({ onClick, className = "", id = "" }) => (
    <button onClick={onClick} className={className} id={id}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon "
            viewBox="0 0 64 64"
        >
            <g stroke="none" strokeWidth="1px">
                <polygon
                    points="51.1,15.1 48.9,12.9 32,29.9 15.1,12.9 12.9,15.1 29.9,32 12.9,48.9 15.1,51.1 32,34.1 48.9,51.1,51.1,48.9 34.1,32"
                    stroke="none"
                    strokeWidth="1px"
                />
            </g>
        </svg>
    </button>
)

export default CloseButton
