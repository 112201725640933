const LOAD_USERS = "LOAD_USERS"
const LOADING_USERS = "LOADING_USERS"
const LOADED_USERS = "LOADED_USERS"
const LOADING_USERS_ABORT = "LOADING_USERS_ABORT"
const LOADING_USERS_ABORTED = "LOADING_USERS_ABORTED"
const SELECT_USER = "SELECT_USER"
const ADD_ROLE = "ADD_ROLE"
const REMOVE_ROLE = "REMOVE_ROLE"
const SEARCH_USERS = "SEARCH_USERS"
const SEARCHING_USERS = "SEARCHING_USERS"
const SEARCH_ABORT = "SEARCH_ABORT"
const SEARCH_ABORTED = "SEARCH_ABORTED"
const SEARCH_FAILED = "SEARCH_FAILED"
const SEARCH_DONE = "SEARCH_DONE"
const SAVING_USER = "SAVING_USER"
const SAVING_USER_ABORTED = "SAVING_USER_ABORTED"
const SAVE_USER = "SAVE_USER"
const USER_SAVED = "USER_SAVED"
const SET_USER_PROPERTY = "SET_USER_PROPERTY"

export {
    LOAD_USERS,
    LOADING_USERS,
    LOADED_USERS,
    LOADING_USERS_ABORT,
    LOADING_USERS_ABORTED,
    SELECT_USER,
    ADD_ROLE,
    REMOVE_ROLE,
    SEARCH_USERS,
    SEARCHING_USERS,
    SEARCH_ABORT,
    SEARCH_ABORTED,
    SEARCH_FAILED,
    SEARCH_DONE,
    SAVING_USER,
    SAVING_USER_ABORTED,
    SAVE_USER,
    USER_SAVED,
    SET_USER_PROPERTY,
}
