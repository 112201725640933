import React, { Component } from "react"
import ImageLogo from "./img/AUTH0-logo.svg"
import version from "../../version"

/**
 * This component has no matching component in roxtec-web-frontend.
 * Instead, this code is fetched from layouts/rt/rt.hbs
 **/
class LogoBar extends Component {
    render() {
        return (
            <div className="layout-rt__bar h-hidden-s-down">
                <img
                    src={ImageLogo}
                    alt="Roxtec Auth0 admin"
                    className="layout-rt__logo"
                    data-testid="logo"
                />
                <div className="layout-rt__version">v{version}</div>
            </div>
        )
    }
}

export default LogoBar
