import auth0 from "auth0-js"

import { LOAD_AUTH, LOADED_AUTH } from "./action_types"

export const login = config => {
    // WebAuth adds properties to the config object, but we have an immutable object
    // so we must make a copy.
    const { connection, ...authOptions } = config
    const auth = new auth0.WebAuth(authOptions)
    auth.authorize({ connection: connection || "roxtec-ad-onprem" })

    return {
        type: LOAD_AUTH,
    }
}

export const handleAuthentication = () => ({
    type: LOADED_AUTH,
})
