import React from "react"
import { connect } from "react-redux"

import { closeModal } from "flows/modal/actions"
import { save, setProperty } from "flows/users/actions"
import Input from "../Input"
import CloseButton from "../CloseButton"
import SaveButton from "../SaveButton"

const details = user => [
    { label: "Title", value: user.title, propertyPath: "title" },
    { label: "Name", value: user.name, propertyPath: "name" },
    { label: "Given name", value: user.given_name, propertyPath: "given_name" },
    { label: "Family name", value: user.family_name, propertyPath: "family_name" },
    { label: "Email", value: user.email, propertyPath: "email" },
    {
        label: "Postal code",
        value: user.address.postal_code,
        propertyPath: "address.postal_code",
    },
    { label: "Region", value: user.address.region, propertyPath: "address.region" },
    {
        label: "Phone number",
        value: user.phone_number,
        propertyPath: "phone_number",
    },
    {
        label: "Street address",
        value: user.address.street_address,
        propertyPath: "address.street_address",
    },
    {
        label: "Locality",
        value: user.address.locality,
        propertyPath: "address.locality",
    },
    {
        label: "Company name",
        value: user.company_name,
        propertyPath: "company_name",
    },
    {
        label: "Customer number",
        value: user.customer_number,
        propertyPath: "customer_number",
    },
]

const EditUserModal = ({
    user,
    closeModal,
    modal,
    save,
    setUserProperty,
    saving,
}) => {
    if (!user) {
        return null
    }

    if (!modal.open) {
        return null
    }

    return (
        <div className="modal" data-testid="edit-user-modal">
            <div className="modal__inner">
                <div className="modal__box">
                    <div className="modal__header">
                        <div className="modal__title">Edit user</div>
                        <CloseButton
                            onClick={closeModal}
                            className="modal__close-button"
                            id="close-edit-user-modal"
                        />
                    </div>
                    <div className="modal__content">
                        {details(user).map(detail => (
                            <Input
                                key={detail.label}
                                onChange={event =>
                                    setUserProperty(detail.propertyPath, event.target.value)
                                }
                                value={detail.value}
                                label={detail.label}
                            />
                        ))}
                        <br />
                        <br />
                        <SaveButton
                            save={save}
                            saving={saving}
                            id="user-modal-save-button"
                            data-testid="user-modal-save-button"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.users.list.find(user => user.selected),
        modal: state.modal,
        saving: state.users.saving,
    }
}

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModal()),
    save: () => dispatch(save()),
    setUserProperty: (propertyPath, value) =>
        dispatch(setProperty(propertyPath, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUserModal)
