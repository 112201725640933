import produce from "immer"

import { LOADED_AUTH, LOADING_AUTH_ERROR } from "./action_types"

const defaultState = {
    accessToken: undefined,
    idToken: undefined,
    expiresAt: undefined,
    error: undefined,
}

export default (state = defaultState, action) => {
    return produce(state, () => {
        switch (action.type) {
        case LOADED_AUTH:
            return {
                ...defaultState,
                ...action.payload,
            }
        case LOADING_AUTH_ERROR:
            return {
                ...defaultState,
                error: action.error,
            }
        default:
            return state
        }
    })
}
