import React from "react"

const Button = ({ text, onClick, id = "", disabled = false, className = "" }) => (
    <button
        className={`button ${className}`}
        onClick={onClick}
        data-testid={id}
        disabled={disabled}
    >
        <span className="button__text">{text}</span>
    </button>
)

export default Button
