import React from "react"
import styled from "styled-components"

import "utilities/hyphenate"
import OptionsGroupItem from "./OptionsGroupItem"

const StyledOptionsGroup = styled.div``

const OptionsGroup = ({ id, type, name, label = "", options = [], onClick }) => (
    <StyledOptionsGroup
        className="options-group options-group--visible"
        data-label={label}
        id={id}
        data-testid={`options-group-${label.hyphenate()}`}
    >
        <div className="options-group__inner">
            <div
                tabIndex="0"
                className="options-group__label"
                data-testid="options-group-label"
            >
                {label}
            </div>
            <div
                className="options-group__content"
                data-testid="options-group-content"
            >
                {options.map((option, index) => (
                    <OptionsGroupItem
                        onClick={() => onClick(index)}
                        type={type}
                        name={name}
                        key={index}
                        {...option}
                    />
                ))}
            </div>
        </div>
    </StyledOptionsGroup>
)

export default OptionsGroup
