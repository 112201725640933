import React from "react"
import classNames from "classnames"

const Layout = ({ main, body, content, children, id, className }) => {
    const classes = classNames(className, {
        "layout-rt": !main && !body && !content,
        "layout-rt__main": main && !body && !content,
        "layout-rt__body": !main && body && !content,
        "layout-rt__content": !main && !body && content,
    })

    return (
        <div className={classes} data-testid={id}>
            {children}
        </div>
    )
}

export default Layout
