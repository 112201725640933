import React from "react"
import classNames from "classnames"

const Section = props => {
    const classes = classNames("section", {
        "section--dark": props.dark,
        "section--ocean-blue": props.oceanBlue,
    })

    return (
        <div className={classes} data-testid="section">
            <div className="section__inner">
                <div className="section__content">{props.children}</div>
            </div>
        </div>
    )
}

export default Section
