import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"

import CloseButton from "../CloseButton"
import { dismissMessages } from "flows/messages/actions"

const Toast = ({ type, message, dismissMessages }) => {
    const classes = classNames("toast", {
        "toast--success": type === "success",
        "toast--error": type === "error",
        "toast--info": type === "info",
    })

    return (
        <div className={classes} data-testid={`toast-${type}`}>
            <div className="icon">
                <div className="square" />
            </div>
            <div className="message">
                <p>{message}</p>
            </div>
            <div className="dismiss">
                <CloseButton
                    onClick={dismissMessages}
                    className="toast__close-button"
                    id="toast-close-button"
                />
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    dismissMessages: () => dispatch(dismissMessages()),
})

export default connect(
    null,
    mapDispatchToProps
)(Toast)
