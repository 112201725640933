import { ADD_FILTER, REMOVE_FILTER } from "./action_types"

export const addFilter = (claim_name, filter_name) => ({
    type: ADD_FILTER,
    claim_name,
    filter_name,
})
export const removeFilter = (claim_name, filter_name) => ({
    type: REMOVE_FILTER,
    claim_name,
    filter_name,
})
