import produce from "immer"

import { SEARCH_TERM_CHANGED } from "./action_types"

const defaultState = {
    search_term: undefined,
}

export default (state = defaultState, action) =>
    produce(state, draft => {
        switch (action.type) {
        case SEARCH_TERM_CHANGED:
            draft.search_term = action.search_term
            break
        default:
            return state
        }
    })
