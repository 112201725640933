import React from "react"
import { connect } from "react-redux"

import Section from "./Section"
import FilterBlock from "./FilterBlock"
import Bar from "./Bar"
import BarColumn from "./BarColumn"
import BarItem from "./BarItem"
import CustomerBar from "./CustomerBar"
import Layout from "./Layout"

const OngoingSearch = () => <Layout content>Searching users...</Layout>

const SearchResult = ({ userList = [], selectUser }) => (
    <Layout content id="user-search-result">
        {userList.map((user, index) => (
            <CustomerBar
                key={index}
                customer={{
                    id: user.id,
                    name: user.name,
                    image_url: user.picture_url,
                    details: [
                        { key: "email", value: user.email },
                        {
                            key: "customer_number",
                            value: user.customer_number,
                        },
                        {
                            key: "company_name",
                            value: user.company_name,
                        },
                    ],
                }}
                labels={userLabels}
                selectCustomer={selectUser}
            />
        ))}
    </Layout>
)

const EmptySearchResult = () => (
    <Layout content id="customer-bar">
    No users found.
    </Layout>
)

const userLabels = {
    email: "Email",
    customer_number: "Customer Number",
    company_name: "Company Name",
}

const UserSearch = ({ users, selectUser }) => (
    <div data-testid="user-search">
        <Section dark>
            <FilterBlock search />
        </Section>
        <Bar>
            <BarColumn>
                <BarItem>
          Result count:{" "}
                    <span data-testid="user-result-count">{users.result_count}</span>
                </BarItem>
            </BarColumn>
        </Bar>
        {!users.searching && users.list.length === 0 && <EmptySearchResult />}
        {users.searching ? (
            <OngoingSearch />
        ) : (
            <SearchResult userList={users.list} selectUser={selectUser} />
        )}
    </div>
)

const mapStateToProps = state => {
    return {
        services: state.roles,
        users: state.users,
    }
}

export default connect(mapStateToProps)(UserSearch)
