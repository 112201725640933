import produce from "immer"

import { OPEN_MODAL, CLOSE_MODAL } from "./action_types"

const defaultState = {
    open: false,
}

export default (state = defaultState, action) =>
    produce(state, draft => {
        switch (action.type) {
        case OPEN_MODAL:
            draft.open = true
            break
        case CLOSE_MODAL:
            draft.open = false
            break
        default:
            return state
        }
    })
