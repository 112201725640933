import { createLogic } from "redux-logic"
import axios from "axios"

import {
    LOAD_ROLES,
    LOADING_ROLES,
    LOADED_ROLES,
    LOADING_ROLES_ABORT,
    LOADING_ROLES_ABORTED,
} from "./action_types"
import { dispatchError } from "utilities/dispatch-error"

const fetchRoles = createLogic({
    // declarative built-in functionality wraps your code
    type: LOAD_ROLES, // only apply this logic to this type
    cancelType: LOADING_ROLES_ABORT, // cancel on this type
    latest: false, // only take latest

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    async process(_, dispatch, done) {
        dispatch({
            type: LOADING_ROLES,
        })

        try {
            const response = await axios.get("/api/roles_per_service", {
                validateStatus: status => status === 200,
            })

            dispatch({
                type: LOADED_ROLES,
                roles_per_service: response.data,
            })
        } catch (err) {
            dispatch({
                type: LOADING_ROLES_ABORTED,
            })

            dispatchError(dispatch, err)
        }

        done()
    },
})

export default [fetchRoles]
