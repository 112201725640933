import React from "react"
import classNames from "classnames"

const UserFigure = ({ inline, float, imageURL }) => {
    const classes = classNames("user-figure", {
        "user-figure--inline": inline,
        "user-figure--float": float,
    })

    return (
        <figure className={classes}>
            <img src={imageURL} alt="profile" />
        </figure>
    )
}

export default UserFigure
