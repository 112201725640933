import produce from "immer"

import {
    LOADING_USERS,
    LOADED_USERS,
    LOADING_USERS_ABORTED,
    SELECT_USER,
    ADD_ROLE,
    REMOVE_ROLE,
    SEARCH_USERS,
    SEARCHING_USERS,
    SEARCH_ABORTED,
    SEARCH_DONE,
    SET_USER_PROPERTY,
    SAVING_USER,
    SAVING_USER_ABORTED,
    USER_SAVED,
} from "./action_types"

import { setPropertyForObject } from "./utilities"

import { SEARCH_TERM_CHANGED } from "../search/action_types"

const defaultState = {
    loading: false,
    saving: false,
    searching: false,
    list: [],
    result_count: undefined,
}

function getPatch(user) {
    user.patch = user.patch || { service_roles: {} }
    return user.patch
}

const selectedUser = list => list.find(user => user.selected)

export default (state = defaultState, action) =>
    produce(state, draft => {
        switch (action.type) {
        case LOADING_USERS:
            draft.loading = true
            break
        case LOADED_USERS:
            draft.loading = false
            draft.list = action.data
            break
        case LOADING_USERS_ABORTED:
            draft.loading = false
            break
        case SELECT_USER:
            draft.list.forEach(user => {
                user.selected = user.id === action.user_id
            })

            break
        case ADD_ROLE:
            {
                const user = selectedUser(draft.list)
                const newList = user.service_roles[action.claim_name].concat(action.role_name)
                user.service_roles[action.claim_name] = newList

                // Record in patch object for subsequent save
                getPatch(user).service_roles[action.claim_name] = newList
            }
            break
        case REMOVE_ROLE:
            {
                const user = selectedUser(draft.list)
                const roles = user.service_roles[action.claim_name]
                const filteredRoles = roles.filter(role => role !== action.role_name)

                user.service_roles[action.claim_name] = filteredRoles

                // Record in patch object for subsequent save
                getPatch(user).service_roles[action.claim_name] = filteredRoles
            }
            break
        case SEARCH_USERS:
            return defaultState
        case SEARCHING_USERS:
            draft.searching = true
            break
        case SEARCH_ABORTED:
            return defaultState
        case SEARCH_DONE:
            draft.searching = false
            draft.list = action.users
            draft.result_count = action.total
            break
        case SET_USER_PROPERTY:
            {
                const user = selectedUser(draft.list)
                setPropertyForObject(
                    user,
                    action.propertyPath,
                    action.value
                )
                setPropertyForObject(user, "unsaved_changes", true)
                
                // Record in patch object for subsequent save
                setPropertyForObject(
                    getPatch(user),
                    action.propertyPath,
                    action.value
                )
            }
            break
        case SAVING_USER:
            draft.saving = true
            break
        case SAVING_USER_ABORTED:
            draft.saving = false
            break
        case USER_SAVED:
            {
                const index = draft.list.indexOf(
                    draft.list.filter(user => user.id === action.user.id)[0]
                )
                draft.list[index] = {
                    ...draft.list[index],
                    unsaved_changes: false,
                    ...action.user,
                }
                // Delete the patch object so that saved changes are not mixed with future changes.
                delete draft.list[index].patch
                draft.saving = false
            }
            break
        case SEARCH_TERM_CHANGED:
            draft.searching = true

            break
        default:
            return state
        }
    })
