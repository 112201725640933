import React from "react"
import Button from "../Button"

const SaveButton = ({ id = "", save, saving, className }) => (
    <Button
        text={saving ? "Saving..." : "Save"}
        onClick={save}
        id={saving ? "saving" : id}
        disabled={saving}
        className={className}
    />
)

export default SaveButton
