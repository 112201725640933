const companyNameKey = "rox_company_name"
const customerNumberKey = "rox_customer_number"

const APIpropertyMap = {
    id: "user_id",
    email: "email",
    picture_url: "picture",
    customer_number: "app_metadata.rox_customer_number",
    title: "user_metadata.rox_title",
    iso_language: "user_metadata.rox_iso_language",
    iso_country: "user_metadata.rox_iso_country",
    unit: "user_metadata.rox_unit",
    company_name: "user_metadata.rox_company_name",
    name: "user_metadata.name",
    given_name: "user_metadata.given_name",
    family_name: "user_metadata.family_name",
    phone_number: "user_metadata.phone_number",
    address: "user_metadata.address",
    region: "user_metadata.rox_region",
}

const extractAppMetadata = (meta = {}, role_names) => {
    let model = {
        customer_number: meta[customerNumberKey],
        service_roles: {},
    }

    role_names.forEach(role_name => {
        const value = Object.prototype.hasOwnProperty.call(meta, role_name) ? meta[role_name] : []

        model["service_roles"][role_name] = value
    })

    return model
}

const extractAddress = meta => ({
    street_address: meta.street_address,
    postal_code: meta.postal_code,
    region: meta.region,
    locality: meta.locality,
})

const extractUserMetadata = (user, meta = {}) => ({
    title: meta.rox_title,
    iso_language: meta.rox_iso_language,
    iso_country: meta.rox_iso_country,
    region: meta.rox_region,
    unit: meta.rox_unit,
    company_name: meta[companyNameKey],
    name: meta.name || user.name,
    given_name: meta.given_name,
    family_name: meta.family_name,
    phone_number: user.phone_number || meta.phone_number,
    address: { ...extractAddress(meta.address || {}) },
})

/* Maps an API user to an internal user.
 * @param {Object} user An API user.
 * @param {string[]} role_names  A list of available service roles names.
 */
const mapUserFromAPIToModel = (user, role_names) => {
    if (!role_names) {
        throw new Error("Missing list of roles")
    }

    return {
        id: user.user_id,
        email: user.email,
        picture_url: user.picture,
        ...extractAppMetadata(user.app_metadata, role_names),
        ...extractUserMetadata(user, user.user_metadata),
    }
}

const mapUsersFromAPIToModel = (users, role_names) =>
    users.map(user => mapUserFromAPIToModel(user, role_names))

// Recursively loop through object and remove keys with undefined value.
// Nested objects are removed if all values are undefined.
// Empty lists are set to null to remove them from the server.
// Any other lists than empty ones are kept as they are.
const removeUndefinedValues = rawObject => {
    let cleanedObject = {}

    for (let property in rawObject) {
        let value = rawObject[property]

        if (!Object.prototype.hasOwnProperty.call(rawObject, property) || value === undefined) {
            continue
        }

        if (Array.isArray(value)) {
            if (value.length === 0) {
                cleanedObject[property] = null
            } else {
                cleanedObject[property] = value
            }

            continue
        }

        if (typeof value === "object") {
            const cleanedNestedObject = removeUndefinedValues(value)

            if (Object.keys(cleanedNestedObject).length > 0) {
                cleanedObject[property] = cleanedNestedObject
            }

            continue
        }

        cleanedObject[property] = value
    }

    return cleanedObject
}

// Sets property value for object. Support nested objects.
//
// Example:
// setPropertyForObject({}, 'address.street_name', 'foobar')
// { address: { street_name: 'foobar' } }
const setPropertyForObject = (object, propertyPath, value) => {
    var keys = propertyPath.split(".")
    let element = object

    for (let index = 0; index < keys.length; index++) {
        let key = keys[index]

        if (index === keys.length - 1) {
            // Last key
            element[key] = value
            break
        }

        if (object[key] === undefined) {
            object[key] = {}
        }

        element = object[key]
    }
}

const addPropertyIfNotDefined = (object, property, value) => {
    if (!Object.prototype.hasOwnProperty.call(object, property)) {
        object[property] = value
    }
}

const objectHasKeys = object =>
    object !== undefined && Object.keys(object).length > 0

const removeReadOnlyProperties = user => {
    delete user.picture_url

    return user
}

/* Maps an internal user to an API user.
 * @param {Object} user A user representation.
 * @param {string[]} role_names  A list of available service roles names.
 */
const mapUserFromModelToAPI = (user, role_names) => {
    if (!role_names) {
        throw new Error("Missing list of roles")
    }

    const cleanedUser = removeUndefinedValues(user)
    let APIuser = {}

    const filteredUser = removeReadOnlyProperties(cleanedUser)

    for (let property in filteredUser) {
        const APIkey = APIpropertyMap[property]
        if (Object.prototype.hasOwnProperty.call(filteredUser, property) && APIkey !== undefined) {
            setPropertyForObject(APIuser, APIkey, filteredUser[property])
        }
    }

    if (objectHasKeys(filteredUser.service_roles)) {
        addPropertyIfNotDefined(APIuser, "app_metadata", {})

        role_names.forEach(role_name => {
            APIuser.app_metadata[role_name] = filteredUser.service_roles[role_name]
        })
    }

    return APIuser
}

export {
    mapUserFromModelToAPI,
    mapUserFromAPIToModel,
    mapUsersFromAPIToModel,
    customerNumberKey,
    companyNameKey,
    setPropertyForObject,
}
