const LOAD_ROLES = "LOAD_ROLES"
const LOADING_ROLES = "LOADING_ROLES"
const LOADED_ROLES = "LOADED_ROLES"
const LOADING_ROLES_ABORT = "LOADING_ROLES_ABORT"
const LOADING_ROLES_ABORTED = "LOADING_ROLES_ABORTED"

export {
    LOAD_ROLES,
    LOADING_ROLES,
    LOADED_ROLES,
    LOADING_ROLES_ABORT,
    LOADING_ROLES_ABORTED,
}
